import React, { useState, useEffect } from 'react';
import logoImg from '../../../assets/img/logo.png';

import { AwesomeButton } from 'react-awesome-button';
import AwesomeButtonStyles from 'react-awesome-button/src/styles/styles.scss';


import Link from '../Link/Link';

const Nav = () => {
  const [navClass, setNavClass] = useState('');
  const [toggeledNav, settoggeledNav] = useState(false);

  const toggleNav = () => {
    settoggeledNav(!toggeledNav);
  };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      let navClass = '';
      if (window.scrollY >= 200) {
        navClass = 'scrolled';
      }
      setNavClass(navClass);
    });
  }, []);


const onClick = () => {
  console.log('Done.')
}


function Button() {
  return (
    <AwesomeButton
      cssModule={AwesomeButtonStyles}
      type="primary"
      onPress={() => {
        window.open("tel:+14109170606", "_self");
      }}>
      &#160;&#160;<i className='fas fa-phone-alt mr-2 inverted'/>&#160;CALL NOW&#160;&#160;&#160;
    </AwesomeButton>
  );
}



  return (
    <nav className={`navbar navbar-expand-md bg-light ${navClass}`}>
      <div className='container'>
        <a className='navbar-brand' href='!#'>
                <img src={logoImg} alt='Mallards Land Care LLC' />

        </a>
            <Button/>
        <div
          className={`navbar-toggler nav-icon ${(() => {
            if (toggeledNav) return 'open';
            return '';
          })()}`}
          onClick={toggleNav}
        >
          <span />
          <span />
          <span />
        </div>
        <div
          className={`collapse navbar-collapse ${(() => {
            if (toggeledNav) return 'show';
            return '';
          })()}`}
        >
          <ul className='navbar-nav ml-auto'>
            <li className='nav-item'>
              <Link target='services' classes='nav-link'>
                Services&#160;&#160;
              </Link>
            </li>
            <li className='nav-item'>
              <Link target='about' classes='nav-link'>
                About&#160;&#160;
              </Link>
            </li>
            <li className='nav-item'>
              <Link target='portfolio' classes='nav-link'>
                Portfolio&#160;&#160;
              </Link>
            </li>
            <li className='nav-item'>
              <Link target='contact' classes='nav-link'>
                Contact&#160;&#160;&#160;&#160;&#160;
              </Link>
              </li>

              <div class="btn-cont">
              <div class="desktop-view-btn">
            </div>
            </div>

          </ul>

        </div>
      </div>
    </nav>

  );
};

export default Nav;
