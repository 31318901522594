import React, { Component } from "react";
import Section from "../../../HOC/Section";
import Image1 from "../../../assets/img/mallards.png";
import { AwesomeButton } from "react-awesome-button";
import AwesomeButtonStyles from "react-awesome-button/src/styles/styles.scss";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

function Button() {
  return (
    <AwesomeButton
      cssModule={AwesomeButtonStyles}
      type="primary"
      onPress={() => {
        window.open("https://www.google.com/localservices/prolist?g2lbs=AIQllVy8ML35iMdtk_VfLK5ZEyI4LrBFs9uJaiHrDhnxSrGjwcEGeG0kk2ckjkCxs791s2fSj6tkVxGLmmSjq_IcRHmdrFpVI9R5w323KWN58gh3tIuoirWE32yXDMKbbvlutMDIyUVsJiV3D_ygmnxfI70p-UN7rw%3D%3D&hl=en-US&gl=us&cs=1&ssta=1&q=Mallards%20Land%20Care%20LLC&oq=Mallards%20Land%20Care%20LLC&slp=MgA6HENoTUluTk9reWZPQmhBTVZGR2xIQVIxUldBb0JSAggCYAB6jgJDaFpOWVd4c1lYSmtjeUJNWVc1a0lFTmhjbVVnVEV4RFNJQ1VtX0NPdW9DQUNGb29FQUFRQVJBQ0VBTVlBQmdCR0FJWUF5SVdiV0ZzYkdGeVpITWdiR0Z1WkNCallYSmxJR3hzWTNvUVFtRnNkR2x0YjNKbElFTnZkVzUwZVpJQkNtTnZiblJ5WVdOMGIzS3FBVmtRQVNvYUloWnRZV3hzWVhKa2N5QnNZVzVrSUdOaGNtVWdiR3hqS0FBeUhSQUJJaGx3RmwzY1NsQzlNMWppZmhCbEtZeTkwWnF0NU5SaEphRnNNaG9RQWlJV2JXRnNiR0Z5WkhNZ2JHRnVaQ0JqWVhKbElHeHNZLUFCQUGSASoKDS9nLzExdjNyMGZsajAKDS9nLzExYzczMzkxNGwSBBICCAESBAoCCAE%3D&src=2&spp=Cg0vZy8xMXYzcjBmbGowOsgBV2lnUUFCQUJFQUlRQXhnQUdBRVlBaGdESWhadFlXeHNZWEprY3lCc1lXNWtJR05oY21VZ2JHeGprZ0VLWTI5dWRISmhZM1J2Y3BvQkFLb0JXUkFCS2hvaUZtMWhiR3hoY21SeklHeGhibVFnWTJGeVpTQnNiR01vQURJZEVBRWlHWEFXWGR4S1VMMHpXT0otRUdVcGpMM1JtcTNrMUdFbG9Xd3lHaEFDSWhadFlXeHNZWEprY3lCc1lXNWtJR05oY21VZ2JHeGo%3D&serdesk=1&lrlstt=1706507444416&ved=2ahUKEwid4J7J84GEAxW9j4kEHTyDAn0QvS56BAgqEAE&scp=ChZnY2lkOmxhd25fY2FyZV9zZXJ2aWNlEkoSEgm3g_TWrgPIiREZKGBxqOB0JyIcQ3JvbXdlbGwgVmFsbGV5LCBIYW1wdG9uLCBNRCoUDVHuexcVp-xZ0h2DaoIXJUFMYdIwARoWTWFsbGFyZHMgTGFuZCBDYXJlIExMQyIWTWFsbGFyZHMgTGFuZCBDYXJlIExMQyoRTGF3biBjYXJlIHNlcnZpY2U%3D#ts=3", "_self");
      }}
    >
      Click here to leave a review
    </AwesomeButton>
  );
}

export default class Testimonials extends Component {
  render() {
    return (
      <Section id="testimonials">
        <div className="container pt-2 pb-5 whitebg">
          <div className="section-header pt-5 pb-5 text-center">
            <h3 className="section-title">
              <span>Customer</span> Reviews
            </h3>
          </div>
          <div className="testimonials-content">


            <Carousel
              showArrows={true}
              infiniteLoop={true}
              showThumbs={false}
              showStatus={false}
              autoPlay={true}
              interval={6100}
            >
              <div>
                <img src="/images/Mona.png" />
                <div className="myCarousel">
                  <h3>Mona Choudhry</h3>
                  <h4>Google Review</h4>
                  <p>
                    I called cody first time when I found his number on one of the local groups. I needed snow removal for a surprise snow storm. He was very responsive, fair in pricing and completed the job as agreed. Will definitely use him for future land care needs. Thank you.
                  </p>



                </div>
              </div>

              <div>
                <img src="/images/Amanda.png" />
                <div className="myCarousel">
                  <h3>Amanda Veloso</h3>
                  <h4>Google Review</h4>
                  <p>
 Here it is 2024 and I have called on Mallards Land Care more times than I can count! Most people (myself included) would think that you'd only call your landscaper during the spring and summer months. But Cody has put his heart and soul into building such a versatile company. I have called him in the fall  for leaf removal, in the winter to remove branches and limbs that have fallen during heavy snowfalls and throughout the entire year to haul my junk away when I get in the mood to purge things from my house and yard! I like to call him my Outdoor Handyman because he really can do it all. I can't say enough great things about Cody @ Mallards Land Care.  I honestly don't know what I'd do without him!
                  </p>
                </div>
              </div>

              <div>
                <img src="/images/Laurie.png" />
                <div className="myCarousel">
                  <h3>Laurie Przybylski</h3>
                  <h4>Google Review</h4>
                  <p>
Thrilled with the job Cody did!  Our gardens look amazing!  Surpassed our expectations! He arrived on time, executed the plan and left with everything cl
                  </p>
                </div>
              </div>


                            <div>
                <img src="/images/Paboopy.png" />
                <div className="myCarousel">
                  <h3>Paboopy</h3>
                  <h4>Google Review</h4>
                  <p>
Contacted them to shovel my aunts sidewalks after the snowstorm. He got back to me quickly and had the job done beautifully within an hour!

                  </p>
                </div>
              </div>

                            <div>
                <img src="/images/Rick.png" />
                <div className="myCarousel">
                  <h3>Rick Urbanski</h3>
                  <h4>Google Review</h4>
                  <p>
I had a gutter that, despite having a leaf guard on top, became clogged. Being 64 years of age and not wanting to risk injury by climbing an extension ladder myself, I posted a request on Nextdoor for some help. Cody offered a fair price and came out within 24 hours and removed the blockage. I found Cody to be very professional and a pleasure to deal with.  I highly recommend Cody and will not hesitate to reach out to him again for other tasks I may need done.
                  </p>
                </div>
              </div>

                            <div>
                <img src="/images/Robin.png" />
                <div className="myCarousel">
                  <h3>Robin Musciano</h3>
                  <h4>Google Review</h4>
                  <p>
Cody came out the  day after I contacted him to get rid of a sectional sofa. And it snowed that morning. He was right on time too. He is a very pleasant young man with an excellent work ethic. It was a pleasure doing business with him! If I ever need anything done in the future, he will be the first person I will call.
                  </p>
                </div>
              </div>


            </Carousel>
            <center>
              <Button />
              <br /><br />
              <br />
                <div className="reviews">
                 <center>
                 <a href="https://www.google.com/localservices/prolist?g2lbs=AIQllVy8ML35iMdtk_VfLK5ZEyI4LrBFs9uJaiHrDhnxSrGjwcEGeG0kk2ckjkCxs791s2fSj6tkVxGLmmSjq_IcRHmdrFpVI9R5w323KWN58gh3tIuoirWE32yXDMKbbvlutMDIyUVsJiV3D_ygmnxfI70p-UN7rw%3D%3D&hl=en-US&gl=us&cs=1&ssta=1&q=Mallards%20Land%20Care%20LLC&oq=Mallards%20Land%20Care%20LLC&slp=MgA6HENoTUluTk9reWZPQmhBTVZGR2xIQVIxUldBb0JSAggCYAB6jgJDaFpOWVd4c1lYSmtjeUJNWVc1a0lFTmhjbVVnVEV4RFNJQ1VtX0NPdW9DQUNGb29FQUFRQVJBQ0VBTVlBQmdCR0FJWUF5SVdiV0ZzYkdGeVpITWdiR0Z1WkNCallYSmxJR3hzWTNvUVFtRnNkR2x0YjNKbElFTnZkVzUwZVpJQkNtTnZiblJ5WVdOMGIzS3FBVmtRQVNvYUloWnRZV3hzWVhKa2N5QnNZVzVrSUdOaGNtVWdiR3hqS0FBeUhSQUJJaGx3RmwzY1NsQzlNMWppZmhCbEtZeTkwWnF0NU5SaEphRnNNaG9RQWlJV2JXRnNiR0Z5WkhNZ2JHRnVaQ0JqWVhKbElHeHNZLUFCQUGSASoKDS9nLzExdjNyMGZsajAKDS9nLzExYzczMzkxNGwSBBICCAESBAoCCAE%3D&src=2&spp=Cg0vZy8xMXYzcjBmbGowOsgBV2lnUUFCQUJFQUlRQXhnQUdBRVlBaGdESWhadFlXeHNZWEprY3lCc1lXNWtJR05oY21VZ2JHeGprZ0VLWTI5dWRISmhZM1J2Y3BvQkFLb0JXUkFCS2hvaUZtMWhiR3hoY21SeklHeGhibVFnWTJGeVpTQnNiR01vQURJZEVBRWlHWEFXWGR4S1VMMHpXT0otRUdVcGpMM1JtcTNrMUdFbG9Xd3lHaEFDSWhadFlXeHNZWEprY3lCc1lXNWtJR05oY21VZ2JHeGo%3D&serdesk=1&lrlstt=1706507444416&ved=2ahUKEwid4J7J84GEAxW9j4kEHTyDAn0QvS56BAgqEAE&scp=ChZnY2lkOmxhd25fY2FyZV9zZXJ2aWNlEkoSEgm3g_TWrgPIiREZKGBxqOB0JyIcQ3JvbXdlbGwgVmFsbGV5LCBIYW1wdG9uLCBNRCoUDVHuexcVp-xZ0h2DaoIXJUFMYdIwARoWTWFsbGFyZHMgTGFuZCBDYXJlIExMQyIWTWFsbGFyZHMgTGFuZCBDYXJlIExMQyoRTGF3biBjYXJlIHNlcnZpY2U%3D#ts=3">
                  <img width="30%" src="/images/reviews.png" />
                  </a>
                  </center>
                </div>


            </center>
          </div>
        </div>
      </Section>
    );
  }
}
