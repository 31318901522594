import React from "react";
import Section from "../../../HOC/Section";
import bgImage from "../../../assets/img/facts_bg.jpg";

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const BackgroundImage = () => (
  <LazyLoadImage
    alt="Background"
    src="../../../assets/img/facts_bg.jpg"
    effect="blur"
    style={{ width: '100%', height: '100vh', objectFit: 'cover' }}
  />
);

// Constants for easy editing
const TOTAL_PROJECT_COUNT = 188;
const TOTAL_HAPPY_CUSTOMER_COUNT = 103;

// Fact item data structure for better maintainability
const FACT_ITEMS = [
  {
    icon: null,
    count: null,
    title: "",
  },
  {
    icon: "fas fa-grin-beam",
    count: TOTAL_HAPPY_CUSTOMER_COUNT,
    title: "Happy Clients",
  },
  {
    icon: "fas fa-project-diagram",
    count: TOTAL_PROJECT_COUNT,
    title: "Total Projects",
  },
  {
    icon: null,
    count: null,
    title: "",
  },
];

const FactItem = ({ icon, count, title }) => (
  <div className="col-sm-6 col-md-3 text-center mb-4">
    {icon && (
      <div className="mb-2 facts-icon">
        <i className={icon} />
      </div>
    )}
    {count && <h4 className="facts-counter text-light">{count}</h4>}
    {title && <h5 className="facts-title text-light">{title}</h5>}
  </div>
);

const Facts = () => {
  return (
    <Section id="facts">
        <div className="container pt-5 pb-4">
          <div className="row facts-fg">
            {FACT_ITEMS.map((item, index) => (
              <FactItem
                key={index}
                icon={item.icon}
                count={item.count}
                title={item.title}
              />
            ))}
          </div>
        </div>

    </Section>
  );
};

export default Facts;