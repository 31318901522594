import React, { useState, useEffect, useMemo } from 'react';

const FallingSnow = () => {
  const [mounted, setMounted] = useState(false);
  
  // Use useMemo to create initial snowflakes only once
  const initialSnowflakes = useMemo(() => Array.from({ length: 35 }, () => ({
    id: Math.random(),
    x: Math.random() * 100, // Initial x position
    size: Math.random() * 4 + 2, // Size between 2-6px
    opacity: Math.random() * 0.5 + 0.2, // Opacity between 0.2-0.7
    speed: Math.random() * 15 + 10, // Fall speed between 10-25s
    delay: Math.random() * -20, // Negative delay for a more natural start
    sway: Math.random() * 2 + 1, // Sway amplitude
    swaySpeed: Math.random() * 2 + 1, // Sway frequency
    blur: Math.random() * 2, // Blur effect
    layer: Math.floor(Math.random() * 3) // Depth layer (0, 1, or 2)
  })), []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setMounted(true);
    }
  }, []);

  if (!mounted) return null;

    const today = new Date();
    const start = new Date(today.getFullYear(), 12, 1); // Dec 1
    const end = new Date(today.getFullYear(), 3, 7); // Mar 7

    const pathIncludesFall = window.location.pathname.includes('/fall');
    if (pathIncludesFall) return;

    const pathIncludesEnablementToken = window.location.pathname.includes('/winter');
    if (!pathIncludesEnablementToken && (today < start || today > end)) return;


  return (
    <>
      <style>
        {`
          @keyframes snowfall {
            0% {
              transform: translateY(-10vh) translateX(0);
              opacity: 0;
            }
            10% {
              opacity: var(--snow-opacity);
            }
            90% {
              opacity: var(--snow-opacity);
            }
            100% {
              transform: 
                translateY(110vh) 
                translateX(calc(sin(var(--sway-angle) * var(--sway-speed)) * var(--sway-distance) * 1px));
              opacity: 0;
            }
          }

          .snowflake {
            position: fixed;
            pointer-events: none;
            background: radial-gradient(circle at center,
              rgba(255, 255, 255, 1) 0%,
              rgba(255, 255, 255, 0.8) 40%,
              rgba(255, 255, 255, 0.2) 80%,
              rgba(255, 255, 255, 0) 100%
            );
            border-radius: 50%;
            animation: snowfall linear infinite;
            will-change: transform;
          }

          .snowflake::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: inherit;
            filter: blur(var(--blur));
            border-radius: 50%;
          }

          @media (prefers-reduced-motion) {
            .snowflake {
              animation: none;
              display: none;
            }
          }
        `}
      </style>
      {initialSnowflakes.map((snowflake) => {
        // Calculate z-index and scale based on layer
        const zIndex = 1000 + snowflake.layer * 10;
        const scale = 1 + (snowflake.layer * 0.5); // Larger snowflakes appear closer
        
        return (
          <div
            key={snowflake.id}
            className="snowflake"
            style={{
              '--snow-opacity': snowflake.opacity,
              '--sway-angle': snowflake.x,
              '--sway-speed': snowflake.swaySpeed,
              '--sway-distance': snowflake.sway * 50,
              '--blur': `${snowflake.blur}px`,
              left: `${snowflake.x}%`,
              width: `${snowflake.size * scale}px`,
              height: `${snowflake.size * scale}px`,
              animationDuration: `${snowflake.speed}s`,
              animationDelay: `${snowflake.delay}s`,
              filter: `blur(${snowflake.blur * scale}px)`,
              opacity: snowflake.opacity,
              zIndex,
              transform: `scale(${scale})`
            }}
          />
        )}
      )}
    </>
  );
};

export default FallingSnow;