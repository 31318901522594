import React from 'react';
import Section from '../../../HOC/Section';
import { AwesomeButton } from "react-awesome-button";
import './custom-styles.css';
import emailjs from "emailjs-com";

const SERVICE_ID = "service_y5t5sgj";
const TEMPLATE_ID = "template_j3nfqle";
const PUBLIC_KEY = "E0isryLUqPDeVhvFv";

function Button() {
  return (
    <AwesomeButton
      cssModule={AwesomeButtonStyles}
      type="primary"
      onPress={() => {
        window.open("tel:+14109170606", "_self");
      }}
    >
      <p style={{ fontSize: "1.4em" }}>
        <i className="fas fa-arrow-circle-right" />
        &ensp;Order Now
      </p>
    </AwesomeButton>
  );
}

const sendEmail = (e) => {
  e.preventDefault();
  emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, PUBLIC_KEY)
    .then((result) => {
      console.log(result.text);
      alert('Thanks! Your message has been received successfully. We will get back to you shortly.');
    }, (error) => {
      console.log(error.text);
      alert(error.text);
    });
  e.target.reset();
}

const ContactForm = () => {
  return (
    <form className="formContainer" onSubmit={sendEmail} style={{ width: '90%', margin: '0 auto', padding: '8px', borderRadius: '5px', backgroundColor: '#f9f9f9', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
      <div className="formElement" style={{ marginBottom: '10px' }}>
        <label htmlFor="from_name" style={{ color: '#333', display: 'block', marginBottom: '5px' }}>Name</label>
        <input type="text" id="from_name" name="from_name" placeholder="Enter your name." required style={{ width: '100%', padding: '5px', borderRadius: '5px', border: '1px solid #ccc' }} />
      </div>

      <div className="formElement" style={{ marginBottom: '15px' }}>
        <label htmlFor="phone_number" style={{ color: '#333', display: 'block', marginBottom: '5px' }}>Phone Number</label>
        <input type="tel" id="phone" name="phone" placeholder="Enter your phone number." required style={{ width: '100%', padding: '5px', borderRadius: '5px', border: '1px solid #ccc' }} />
      </div>

      <div className="formElement" style={{ marginBottom: '15px' }}>
        <label htmlFor="message" style={{ color: '#333', display: 'block', marginBottom: '5px' }}>Message</label>
        <textarea name="message" rows="8" cols="30" placeholder="Enter your private message to the owners of Mallards Land Care LLC." required style={{ width: '100%', padding: '5px', borderRadius: '5px', border: '1px solid #ccc' }} />
      </div>
      <button type="submit" className="formButton" style={{ width: '100%', backgroundColor: '#334b15', color: 'white', padding: '10px 20px', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>Submit</button>

      <br />
      <img 
        src="https://www.mallards.llc/images/ssl.png" 
        alt="SSL secured form" 
        style={{ width: '64px', float: 'right'}} 
      />

      <br /> <br />

    </form>
  );
}

const contact = () => {
  return (
    <Section id='contact'>
      <div className='container pt-2 pb-5'>
        <div className='section-header pt-5 pb-5 text-center'>
          <h3 className='section-title'>
            <span>Contact </span>Us
          </h3>
          <center>
          <h6 className='section-subtitle mr-auto ml-auto'>
            Please use the form below to contact us and we will get back to you
            as soon as possible.
          </h6>
          </center>
        </div>
        <div className='section-content'>
          <div className='row'>
            <div className='col-md-9 col-lg-7 mr-auto ml-auto'>
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default contact;
