import React from 'react';

import Section from '../../../HOC/Section';



const Blog = () => {
  return (
    <Section id='blog'>

    </Section>
  );
};

export default Blog;
