import React from "react";

import Section from "../../../HOC/Section";
import ImageGallery from "react-image-gallery";

const images = [
  {
    original: "images/wip1.webp",
    thumbnail: "images/wip1.webp",
  },
    {
   original: "images/wip2.webp",
    thumbnail: "images/wip2.webp",
  },
  {
   original: "images/wip3.webp",
    thumbnail: "images/wip3.webp",
  },
  {
   original: "images/wip4.webp",
    thumbnail: "images/wip4.webp",
  },
    {
   original: "images/wip5.webp",
    thumbnail: "images/wip5.webp",
  },
    {
   original: "images/wip6.webp",
    thumbnail: "images/wip6.webp",
  },
];

class MyGallery extends React.Component {
  render() {
    return <ImageGallery items={images} />;
  }
}

const WorkInProgress = () => {
  return (
    <Section id="wip">
      <div className="container pt-2 pb-5">
        <div className="card rounded-0">
          <div className="section-header pt-5 pb-5 text-center">
            <h3 className="section-title">
              <span>Our</span> Work-in-Progress
            </h3>
            <center>
            <h6 className="section-subtitle mr-auto ml-auto">
                <MyGallery />
            <div class="bottom-footer pt-3 pb-3 text-center tiny"><small>Photography ©2024 Mallards Land Care LLC.</small></div>
            </h6>
            </center>
          </div>
        </div>
        <div className="section-content">
          <div className="row"></div>
        </div>
      </div>
    </Section>
  );
};

export default WorkInProgress;
