import React from "react";

import Section from "../../../HOC/Section";
import clearingImg from "../../../assets/img/clearing.webp";
import gardeningImg from "../../../assets/img/gardening.webp";
import landscapingImg from "../../../assets/img/landscaping.webp";
import irrigationImg from "../../../assets/img/irrigation.webp";
import trimmingImg from "../../../assets/img/fallentree.webp";
import bushremovalImg from "../../../assets/img/bushremoval.webp";
import lampsImg from "../../../assets/img/lamps.webp";
import mowingImg from "../../../assets/img/mowing.webp";
import walkwaysImg from "../../../assets/img/walkways.webp";
import leavesImg from "../../../assets/img/leafremoval.webp";
import mulchingImg from "../../../assets/img/mulching.webp";
import stumpImg from "../../../assets/img/trimming.webp";
import flowerbedImg from "../../../assets/img/flowerbed.webp";
import { AwesomeButton } from "react-awesome-button";
import AwesomeButtonStyles from "react-awesome-button/src/styles/styles.scss";

function Button() {
  return (
    <AwesomeButton
      cssModule={AwesomeButtonStyles}
      type="primary"
      onPress={() => {
        window.open("tel:+14109170606", "_self");
      }}
    >
      <p style={{ fontSize: "1.4em" }}>
        <i className="fas fa-arrow-circle-right" />
        &ensp;Order Services
      </p>
    </AwesomeButton>
  );
}

const Service = () => {
  return (
    <Section id="services">
      <div className="container pt-2 pb-5">
          <div className="section-header pt-5 pb-5 text-center service-spacer">
            <h3 className="section-title">
              <span>
               Our <span>Services</span>
              </span>
            </h3>
        </div>
        <div className="section-content">
          <div className="section-content">
            <br />
            <div className="card rounded-0 clearing">
              <img
                src={clearingImg}
                className="clearing"
                alt="Image of land clearing"
              />
              <div className="card-body">
                <center>
                  <h5 className="card-title">
                    Brush Clearing
                  </h5>
                  <p className="card-text"></p>
                </center>
              </div>
            </div>
            <br />
                <div className="card rounded-0 clearing">
              <img
                src={landscapingImg}
                className="clearing"
                alt="Image of land clearing"
              />
              <div className="card-body">
                <center>
                  <h5 className="card-title">
                    Landscaping Design, Landscaping Installation, Landscape Repair, Landscape Cleanup
                  </h5>
                  <p className="card-text"></p>
                </center>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-lg-4 mb-3">
                <div className="card rounded-0">
                  <img
                    src={mowingImg}
                    className="card-img-top img-cover"
                    alt="Image of landscaping"
                  />
                  <div className="card-body">
                    <center>
                    <h5 className="card-title">Lawn Mowing</h5>
                    </center>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mb-3">
                <div className="card rounded-0">
                  <img
                    src={trimmingImg}
                    className="card-img-top img-cover"
                    alt="Image of tree trimming"
                  />
                  <div className="card-body">
                    <center>
                    <h5 className="card-title">Fallen Tree Removal</h5>
                    </center>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mb-3">
                <div className="card rounded-0">
                  <img
                    src={stumpImg}
                    className="card-img-top img-cover"
                    alt="Image of powerwashing"
                  />
                  <div className="card-body">
                  <center>
                    <h5 className="card-title">Tree Stump Removal</h5>
                    </center>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 mb-3">
                <div className="card rounded-0">
                  <img
                    src={mulchingImg}
                    className="card-img-top img-cover"
                    alt="Image of mulching"
                  />
                  <div className="card-body">
                  <center>
                    <h5 className="card-title">Mulching</h5>
                    </center>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mb-3">
                <div className="card rounded-0">
                  <img
                    src={gardeningImg}
                    className="card-img-top img-cover"
                    alt="Image of gardening & weeding"
                  />
                  <div className="card-body">
                  <center>
                    <h5 className="card-title">Gardening & Weeding</h5>
                    </center>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mb-3">
                <div className="card rounded-0">
                  <img
                    src={flowerbedImg}
                    className="card-img-top img-cover"
                    alt="Image of stone flowerbed"
                  />
                  <div className="card-body">
                  <center>
                    <h5 className="card-title">Stone Flowerbeds</h5>
                    </center>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 mb-3">
                <div className="card rounded-0">
                  <img
                    src={bushremovalImg}
                    className="card-img-top img-cover"
                    alt="Image of bush/shrub removal"
                  />
                  <div className="card-body">
                  <center>
                    <h5 className="card-title">Bush & Shrub Removal</h5>
                    </center>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mb-3">
                <div className="card rounded-0">
                  <img
                    src={leavesImg}
                    className="card-img-top img-cover"
                    alt="Image of leaf clearing & leaf removal"
                  />
                  <div className="card-body">
                  <center>
                    <h5 className="card-title">Leaf Removal</h5>
                    </center>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mb-3">
                <div className="card rounded-0">
                  <img
                    src={walkwaysImg}
                    className="card-img-top img-cover"
                    alt="Image of a stone walkway"
                  />
                  <div className="card-body">
                  <center>
                    <h5 className="card-title">Walkways & Patios</h5>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <center>
                      <h6 className="section-subtitle">
                For more services, please contact us.
              </h6> 
          <Button />
          <br />   
          </center>
      </div>
    </Section>
  );
};

export default Service;
