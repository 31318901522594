import React from "react";

import Section from "../../../HOC/Section";
import bgImage from "../../../assets/img/home_bg.jpg";
import sloganImage from "../../../assets/img/slogan.png";
import Link from "../../UI/Link/Link";
import { AwesomeButton } from "react-awesome-button";
import AwesomeButtonStyles from "react-awesome-button/src/styles/styles.scss";

function Button() {
  return (
    <AwesomeButton
      cssModule={AwesomeButtonStyles}
      type="primary"
      onPress={() => {
        window.open("tel:+14109170606", "_self");
      }}
    >
      <p style={{ fontSize: "1.4em" }}>
        <i className="fas fa-arrow-circle-right" />
        &ensp;Get A Free Estimate
      </p>
    </AwesomeButton>
  );
}

function Button2() {
  return (
    <AwesomeButton
      cssModule={AwesomeButtonStyles}
      type="primary"
      onPress={() => {
        window.open("tel:+14109170606", "_self");
      }}
    >
      <i className="fas fa-map-marker-alt mr-2" />
      &#8250;&#8250; CLICK HERE FOR A FREE QUOTE &#8249;&#8249;
      <i className="fas fa-angles-right mr-2" />
    </AwesomeButton>
  );
}

const home = () => {
  return (
    <Section id="home">
      <div>
        <div
          className="home-content p-5 noscroll"
          style={{ backgroundImage: `url(${bgImage})` }}
        >
          <div className="intro container text-center text-light noscroll">
            <center>
              <img src={sloganImage} alt="we do it right... the first time" />
              <br />
              <h6 class="gr1"><b><u>Environmentally Responsible Land Care</u></b></h6>

              <h6 className="section-subtitle mr-auto ml-auto">
                <p class="gr2">
                  Transforming landscapes one project at a time! Our dedicated
                  team provides professional landscaping services for
                  all commercial and residential properties in the Harford County and Baltimore County region. 
                  From design to installation, we’ve got you covered! Contact us
                  today to schedule a consultation.{" "}
                </p>
              </h6>
            </center>

         <div className="yesclick">
            <Button />
            </div>
            <br />
          </div>
        </div>
      </div>
    </Section>
  );
};

export default home;
