import React from 'react';

import Nav from '../Nav/Nav';
import TopBar from '../TopBar/TopBar';

const Header = () => {
  // Helper function to get the current season
  const getCurrentSeason = () => {
    const currentDate = new Date();
    const month = currentDate.getMonth() + 1;

    if (month >= 9 && month <= 12) {
      return 'SPECIAL OFFER: THIS FALL GET 10% OFF LEAF CLEARING / LEAF REMOVAL SERVICES. APPLIES TO 1 ORDER ONLY. PROMO MUST BE MENTIONED BEFORE PAYMENT TO QUALIFY. LIMITED TIME ONLY. OFFER ENDS: NOV 31, 2024.';
    } else if (month >= 1 && month <= 3) {
      return 'SPECIAL OFFER: This Winter 15% OFF ALL NEW CUSTOMER ORDERS. APPLIES TO 1 ORDER ONLY. COUPON MUST BE MENTIONED AT TIME OF CHECKOUT TO QUALIFY. LIMITED TIME ONLY. Offer Ends: MAR 7, 2025 ~~~';
    } else if (month >= 4 && month <= 6) {
      return 'SPECIAL OFFER: This Spring only get 10% OFF gardening services. APPLIES TO 1 ORDER ONLY. COUPON MUST BE MENTIONED AT TIME OF CHECKOUT TO QUALIFY.  LIMITED TIME ONLY ~~~ Offer Ends: SPRING END 2025 ~~~';
    } else {
      return 'SPECIAL OFFER: This Summer get 10% OFF LAWN MOWING SERVICES. APPLIES TO 1 ORDER ONLY. COUPON MUST BE MENTIONED AT TIME OF CHECKOUT TO QUALIFY.  LIMITED TIME ONLY ~~~ Offer Ends: SUMMERS END 2025 ~~~';
    }
  };

  const currentSeason = getCurrentSeason();



  return (


    <div className="header">

      <div className="banner">
          <marquee width="100%">
            <i className="fas fa-gift mr-2" />
            {currentSeason}
          </marquee>
        
      </div>


      <TopBar />
      <Nav />
    </div>
  );
};

window.addEventListener('scroll', function() {
  if (window.scrollY >= 444) {
    document.getElementsByClassName('banner')[0].style.display = 'none';
  } else {
    document.getElementsByClassName('banner')[0].style.display = 'block';
  }
});

export default Header;