import React from "react";

import Section from "../../../HOC/Section";
import ImageGallery from "react-image-gallery";

const images = [
  {
    original: "images/house.webp",
    thumbnail: "images/house.webp",
  },
    {
    original: "images/3.webp",
    thumbnail: "images/3.webp",
  },
  {
    original: "images/project2.webp",
    thumbnail: "images/project2.webp",
  },
  {
    original: "images/project1.webp",
    thumbnail: "images/project1.webp",
  },
    {
    original: "images/g4.webp",
    thumbnail: "images/g4.webp",
  },
    {
    original: "images/g5.webp",
    thumbnail: "images/g5.webp",
  },
  {
    original: "images/flowerbed.webp",
    thumbnail: "images/flowerbed.webp",
  },
  {
    original: "images/garden.webp",
    thumbnail: "images/garden.webp",
  },
    {
    original: "images/1.webp",
    thumbnail: "images/1.webp",
  },
    {
    original: "images/2.webp",
    thumbnail: "images/2.webp",
  },
      {
    original: "images/5.webp",
    thumbnail: "images/5.webp",
  },
    {
    original: "images/g1.webp",
    thumbnail: "images/g1.webp",
  },
];

class MyGallery extends React.Component {
  render() {
    return <ImageGallery items={images} />;
  }
}

const Portfolio = () => {
  return (
    <Section id="portfolio">
      <div className="container pt-2 pb-5">
        <div className="card rounded-0">
          <div className="section-header pt-5 pb-5 text-center">
            <h3 className="section-title">
              <span>Our</span> Completed Projects
            </h3>

            <center>
            <h6 className="section-subtitle mr-auto ml-auto">
              <p>See some examples of our recently completed projects:</p>

                <MyGallery />
               <div class="bottom-footer pt-3 pb-3 text-center tiny"><small>Photography ©2024 Mallards Land Care LLC.</small></div>
            </h6>
           </center>

          </div>
        </div>
        <div className="section-content">
          <div className="row"></div>
        </div>
      </div>
    </Section>
  );
};

export default Portfolio;
