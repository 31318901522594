import React, { useState, useEffect } from "react";
import Section from "../../../HOC/Section";
import aboutImageFall from "../../../assets/img/about-fall.webp";
import aboutImageWinter from "../../../assets/img/about-winter.webp";
import aboutImageSpringSum from "../../../assets/img/about-spring-summer.webp";
import subsloganImage from "../../../assets/img/subslogan.webp";
import mImage from "../../../assets/img/mallards.webp";
import { AwesomeButton } from "react-awesome-button";
import AwesomeButtonStyles from "react-awesome-button/src/styles/styles.scss";
import ImageGallery from "react-image-gallery";
import Testimonials from "./testimonials.js";
import clearingImg from "../../../assets/img/clearing.webp";
import './custom-styles.css';


const images = [
  {
    original: "https://picsum.photos/id/1018/1000/600/",
    thumbnail: "https://picsum.photos/id/1018/250/150/",
  },
  {
    original: "https://picsum.photos/id/1015/1000/600/",
    thumbnail: "https://picsum.photos/id/1015/250/150/",
  },
  {
    original: "https://picsum.photos/id/1019/1000/600/",
    thumbnail: "https://picsum.photos/id/1019/250/150/",
  },
];

function Button() {
  return (
    <AwesomeButton
      cssModule={AwesomeButtonStyles}
      type="primary"
      onPress={() => {
        window.open("tel:+14109170606", "_self");
      }}
    >
      <p style={{ fontSize: "1.4em" }}>
        <i className="fas fa-arrow-circle-right" />
        &ensp;Order Now
      </p>
    </AwesomeButton>
  );
}


function Button2() {
  return (
    <AwesomeButton
      cssModule={AwesomeButtonStyles}
      type="secondary"
      onPress={() => {
        window.open("tel:+14109170606", "_self");
      }}
    >
      <p style={{ fontSize: "1.4em" }}>
        <i className="fas fa-arrow-circle-right" />
        &ensp;Order Now
      </p>
    </AwesomeButton>
  );
}

class MyGallery extends React.Component {
  render() {
    return <ImageGallery items={images} />;
  }
}

const seasonalContent = {
  fall: {
    description: `Don't let falling leaves bury your beautiful landscape under a sea of red and gold. At Mallards LLC, we provide professional leaf clearing services that keep your property pristine and protect your lawn's health through the autumn months. Our efficient team uses state-of-the-art equipment to thoroughly remove leaves, prevent dead spots in your grass, and help safeguard against seasonal pests and mold growth. Schedule your leaf clearing service today, and let us handle the heavy lifting while you enjoy the beauty of fall without the backbreaking work.`,
    image: aboutImageFall,
    title: "Leaf Clearing / Leaf Removal",
  },
  winter: {
    description: `Enjoy the beauty of your property during the wintertime without the hassle. At Mallards LLC, we provide professional winter care services that keep your property pristine and accessible through the colder months. Our team uses state-of-the-art equipment to ensure your pathways and driveways remain clear, preventing icy hazards and maintaining the safety and beauty of your outdoor spaces. Schedule your winter care service today, and let us handle the heavy lifting while you enjoy the serene beauty of winter without the backbreaking work.`,
    image: aboutImageWinter,
    title: "Winter Snow Services",
  },
  springSummer: {
    description: `Keep your landscape looking lush and vibrant throughout the warmer months with Mallards LLC's professional lawn mowing services. Our expert team uses top-of-the-line equipment to ensure your lawn is perfectly trimmed and healthy. Regular mowing not only enhances the beauty of your property but also promotes strong, resilient grass that can withstand the summer heat. Schedule your lawn mowing service today, and let us take care of the hard work while you enjoy a pristine, green lawn all season long.`,
    image: aboutImageSpringSum,
    title: "Lawn Mowing Services",
  },
};

const About = () => {
  const [currentSeason, setCurrentSeason] = useState("");
  const [currentContent, setCurrentContent] = useState(seasonalContent.springSummer);

  useEffect(() => {
    const determineCurrentSeason = () => {
      const currentDate = new Date();
      const month = currentDate.getMonth(); // 0-11 where 0 is January

      if (month >= 9 && month <= 11) { // October to December
        setCurrentSeason("fall");
        setCurrentContent(seasonalContent.fall);
      } else if (month >= 0 && month <= 2) { // January to March
        setCurrentSeason("winter");
        setCurrentContent(seasonalContent.winter);
      } else { // April to September
        setCurrentSeason("springSummer");
        setCurrentContent(seasonalContent.springSummer);
      }
    };

    determineCurrentSeason();
  }, []); // Empty dependency array means this runs once on component mount

  return (
    <Section id="about">
      <head>
        <link
          rel="stylesheet"
          href="https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css"
        />
      </head>

      <div className="container pt-2 pb-5">
        <div className="card rounded-0">
          <div className="section-header pt-5 pb-5 text-center">
            <h3 className="section-title">
              <span>About</span> Us
            </h3>
            <center>
            <h6 className="section-subtitle mr-auto ml-auto">
              <p>
                Mallards Land Care LLC is a Maryland based local landscaping business. We specialize in providing a safe and
                environmentally friendly approach to landscaping services. As a small family-owned and operated business, we provide
                attention to detail that larger companies simply can't match. We work closely 
                with Homeowners, Farms and Ranches, Property Management Companies, and more.
              </p>


              <div className="licensedText">
                <i className="fas fa-check mr-2" />
                &#8201;Professional Service
              </div>

              <div className="licensedText">
                <i className="fas fa-clock mr-2" />
                &#8201;Fast Response Time
              </div>

              <div className="licensedText">
                <i className="fa fa-shield" mr-2 />
                &#8201;Licensed & Insured
              </div>
            </h6>
            </center>
                          <br/>              <br/>

 <div className="padded biggest">
              <center>
                🏆<br />

              <i>“The Mallards Difference: We do it right, <u>the 1<span style={{ verticalAlign: "super", fontSize: "0.7rem" }}>st</span> time</u>!”</i></center>
              </div>
          </div>
        </div>
      </div>

      <center>
        <div className="rowContainer rounder">
          <div className="row centered light rounder">
            <div className="col-sm-2 col-md-2 text-center mb-1">
              <h4 className="facts-counter text-light">
                <i className="fas fa-home" /><div className="biggest">1</div>
              </h4>
              <p className="text-light bigger">
                Tell Us About Your Project
              </p>
            </div>

            <div className="col-sm-2 col-md-2 text-center mb-1">
              <h4 className="facts-counter text-light">
                <i className="fas fa-truck" /><div className="biggest">2</div>
              </h4>
              <p className="text-light bigger">
                Get a Custom Tailored Execution<br/>Plan and Estimate
              </p>
            </div>

            <div className="col-sm-2 col-md-2 text-center mb-1">
              <h4 className="facts-counter text-light">
                <i className="fas fa-arrow-circle-right" /><div className="biggest">3</div>
              </h4>
              <p className="text-light bigger">
                Sit Back and Relax While We<br/>Transform Your Land
              </p>
            </div>
          </div>
        </div>
      </center>
      <div className="container pt-2 pb-5">
        <div className="section-header pt-5 pb-5 text-center">
          <div className="card rounded-0 clearing">
            <div className="card-body">
              <center>
                <h3 className="section-title">
                  <span>Service</span> of the Season
                </h3>

                <div className="section-content">
                  <div className="row">
                    <div className="col-md-12 col-lg-6 mb-3">
                      <div className="aboutImage">
                        <img src={currentContent.image} alt="about company" />
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-6">
                      <h5 className="section-title2line">
                      <div>Save $$$ this season on:</div>
                        <span>
                          <i className="fas fa-leaf mr-2" />
                          {currentContent.title}
                        </span>
                      </h5>
                      <div className="about-description">
                        <p>{currentContent.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
                      <center>
                       <Button />
                      </center>

              </center>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default About;