import React from "react";
import Section from "../../../HOC/Section";

const mapMarker = () => {
  return (
    <Section id="map">
      <div className="container pt-2 pb-5">
        <div className="card rounded-0">
          <div className="section-header pt-5 pb-5 text-center">
            <h3 className="section-title">
              <span>
                <i className="fas fa-map-marker-alt mr-2" /> Service Area
              </span>
            </h3>

            <center></center>

            <div>
              <iframe
                title="mapMarker"
                src="https://www.google.com/maps/d/embed?mid=1xqNfewEJlQsLMm96zI6OWn85guT4wQU&ehbc=2E312F"
                width="100%"
                height="450"
                frameBorder="0"
                style={{ border: 0, marginBottom: "-7px" }}
                allowFullScreen
              />
            </div>
            <p>
              <h5 className="section-title2">
                We service everywhere in{" "}
                <span>
                  <u>Harford County</u>
                </span>{" "}
                &{" "}
                <span>
                  <u>Baltimore County</u>
                </span>
              </h5>
              <br />
              Aberdeen, Abingdon, Arbutus, Baltimore Highlands, Bel Air,
              Belcamp, Benson, Bowleys Quarters, Carney, Catonsville,
              Cockeysville, Churchville, Darlington, Dundalk, Dublin, Edgemere,
              Edgewood, Essex, Fallston, Forest Hill, Garrison, Hampton, Havre
              de Grace, Jarrettsville, Joppa, Joppatowne, Lansdowne-Baltimore
              Highlands, Lochearn, Loch Raven, Lutherville-Timonium, Mays
              Chapel, Middle River, Milford Mill, Overlea, Owings Mills,
              Parkville, Perry Hall, Perryman, Pikesville, Pylesville,
              Randallstown, Reisterstown, Rosedale, Rossville, Street, Towson,
              and White Marsh.
            </p>

            <center>
              <h6 className="section-subtitle">
                For other areas, please contact us.
              </h6>
            </center>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default mapMarker;
