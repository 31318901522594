import React, { useState, useEffect } from 'react';

const FallingLeavesEffect = () => {
  const [leaves, setLeaves] = useState([]);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setMounted(true);
    }
  }, []);

  useEffect(() => {
    if (!mounted) return;

    const today = new Date();
    const start = new Date(today.getFullYear(), 8, 22); // September 22
    const end = new Date(today.getFullYear(), 10, 30); // November 30

    const pathIncludesWinter = window.location.pathname.includes('/winter');
    if (pathIncludesWinter) return;

    const pathIncludesEnablementToken = window.location.pathname.includes('/fall');
    if (!pathIncludesEnablementToken && (today < start || today > end)) return;

    // Fall colors palette
    const fallColors = [
      '#FF6B35', // Orange
      '#CF4520', // Dark Orange
      '#8B0000', // Dark Red
      '#FFB84C', // Golden Yellow
      '#A94A25', // Brown
    ];

    // Leaf generation function
    const createLeaf = () => ({
      id: Date.now() + Math.random(),
      left: `${Math.random() * 100}%`,
      size: Math.random() * 20 + 15, // Larger leaves (15-35px)
      color: fallColors[Math.floor(Math.random() * fallColors.length)],
      rotationSpeed: Math.random() * 15 + 10, // Base rotation speed
      swayAmount: -300 + Math.random() * 600, // Horizontal sway
      fallDuration: Math.random() * 15 + 10, // 10-25 seconds to fall
      delay: `${Math.random() * 5}s`,
      initialRotation: Math.random() * 360,
      swayFrequency: Math.random() * 2 + 1, // How fast it sways
      zIndex: Math.floor(Math.random() * 10), // 3D effect with different z-indices
      leafType: Math.floor(Math.random() * 4) + 1 // Randomly select 1-4 for leaf type
    });

    // Create initial leaves
    const initialLeafCount = 20;
    const initialLeaves = Array.from({ length: initialLeafCount }, createLeaf);
    setLeaves(initialLeaves);

    // Add new leaves periodically
    const intervalId = setInterval(() => {
      setLeaves(prev => [...prev.slice(-20), createLeaf()]); // Keep max 40 leaves
    }, 2000);

    return () => clearInterval(intervalId);
  }, [mounted]);

  if (!mounted) return null;

  // Return the file path of one of the four SVG files
  const createLeafSVG = (leafType) => `https://mallards.llc/leaf${leafType}.svg`;

  return (
    <>
      <style>{`
        @keyframes falling {
          0% {
            transform: 
              translateY(-10%)
              translateX(0)
              rotateX(0)
              rotateY(0)
              rotateZ(0);
            opacity: 1; /* Start fully visible */
          }
          70% {
            transform: 
             opacity: 1; /* Remain fully visible */
          }
          100% {
            transform:
              translateY(100vh)
              translateX(var(--sway-amount))
              rotateX(360deg)
              rotateY(360deg)
              rotateZ(360deg);
            opacity: 0; /* Fade out at the end */
          }
        }

        .falling-leaf {
          position: fixed;
          top: -10%;
          transform-style: preserve-3d;
          animation: falling linear infinite;
          pointer-events: none;
        }
      `}</style>

      {leaves.map(leaf => (
        <div
          key={leaf.id}
          className="falling-leaf"
          style={{
            left: leaf.left,
            width: `${leaf.size}px`,
            height: `${leaf.size}px`,
            zIndex: leaf.zIndex,
            animationDuration: `${leaf.fallDuration}s`,
            animationDelay: leaf.delay,
            '--sway-amount': `${leaf.swayAmount}px`,
            background: `url('${createLeafSVG(leaf.leafType)}') no-repeat`,
            backgroundSize: 'contain',
            perspective: '1000px',
            transformStyle: 'preserve-3d'
          }}
        />
      ))}
    </>
  );
};

const FallingLeaves = () => (
  <FallingLeavesEffect />
);

export default FallingLeaves;
