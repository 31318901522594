import React from 'react';
import Link from '../../UI/Link/Link';

const topBar = () => {
  return (

    <div className='top-bar pt-1 pb-1 text-light'>
      <div className='container'>
        <div className='d-flex justify-content-between'>
          <div className='d-flex pt-2 pb-2'>
            <div className='social-media d-flex align-item-center'>
                  <a href="https://www.facebook.com/people/Mallards-Land-Care/61550310202299/" className="text-light"><i className="fab fa-facebook-f mr-4"></i></a>
                <a href="https://nextdoor.com/pages/mallards-land-care-baltimore-md?init_source=org_pages" className="text-light"><img style={{ width: "15px", height: "15px", padding: "0px", margin: "0px", marginTop: "-4px" }} src="https://mallards.llc/images/nextdoor.png"/></a>
                <a href="https://www.instagram.com/mallardsllc/" className="text-light"><i className="fab fa-instagram mr-4"></i></a>
            </div>
          </div>
          <div className='d-flex pt-2 pb-2'>
            <div className='d-flex align-item-center mr-3'>
              <i className='fas fa-phone-alt mr-2' />
              <small><a className="superboldfont" href="tel:4109170606">(410) 917-0606</a></small>
            </div>
          </div>
		  
		  
        </div>
      </div>
    </div>
  );
};

export default topBar;
