import React, { Fragment } from 'react';

import Home from './Home/Home';
import About from './About/About';
import Portfolio from './Portfolio/Portfolio';
import WorkInProgress from './WorkInProgress/WorkInProgress';
import Service from './Service/Service';
import Facts from './Facts/Facts';
import Testimonials from './Testimonials/Testimonials';
import Blog from './Blog/Blog';
import MapMarker from './MapMarker/MapMarker';
import Contact from './Contact/Contact';
import ReactDOM from 'react-dom';


const sections = () => {
  return (
    <Fragment>
      <Home />
      <About />
      <Service />
      <MapMarker />
      <Portfolio />
      <Testimonials />
      <WorkInProgress />
      <Facts />
      <Contact />
    </Fragment>
  );
};




export default sections;
