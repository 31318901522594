import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import Link from '../Link/Link';
import { AwesomeButtonSocial } from 'react-awesome-button';
import FallingSnow from './FallingSnow.js';
import FallingLeaves from './FallingLeaves.js';

const Footer = () => {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    // Check for year change every minute
    const interval = setInterval(() => {
      const newYear = new Date().getFullYear();
      if (newYear !== currentYear) {
        setCurrentYear(newYear);
      }
    }, 60000); // Check every minute

    return () => clearInterval(interval);
  }, [currentYear]);

  return (
    <footer className="relative">
      <div className="container text-light pt-5 relative z-10">
        <div className="row">
          <div className="col-sm-6 col-md-6 col-lg-3 mb-5">
            <div className="footer-title">
              <h6>Contact Us</h6>
            </div>
            <div className="footer-content">
            <i className="fas fa-phone-alt mr-2"></i>
            <small>Phone: <a href="tel:+14109170606">(410) 917-0606</a></small><br />
            <i className="fas fa-map-marker mr-2"></i>
            <small>Office: <a href="https://www.google.com/maps/dir/?api=1&destination=6521+Blackhead+Rd+Middle+River+MD+21220" target="_blank">6521 Blackhead Rd. Middle River MD</a></small><br />
              <i className="fas fa-envelope mr-2"></i>
              <small>
                E-mail: <a href="mailto:Cody@Mallards.LLC">Cody@Mallards.LLC</a>
              </small>
              <br />

            </div>
          </div>

          <div className="col-sm-6 col-md-6 col-lg-3 mb-5">
            <div className="footer-title">
              <h6>Follow Us</h6>
            </div>
            <div className="footer-content">
              <div className="social-media d-flex align-item-center">
                <a href="https://www.facebook.com/people/Mallards-Land-Care/61550310202299/" className="text-light"><i className="fab fa-facebook-f mr-4"></i></a>
                <a href="https://nextdoor.com/pages/mallards-land-care-baltimore-md?init_source=org_pages" className="text-light"><img style={{ width: "15px", height: "15px", padding: "0px", margin: "0px", marginTop: "-4px" }} src="https://mallards.llc/images/nextdoor.png"/></a>
                <a href="https://www.instagram.com/mallardsllc/" className="text-light"><i className="fab fa-instagram mr-4"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      
       <div className="fallingLeaves">
         <FallingLeaves />
      </div>

           <div className="fallingSnow">
         <FallingSnow />
      </div>


      <div className="bottom-footer pt-3 pb-3 text-center">
        <small>©{currentYear} Mallards Land Care LLC - All Rights Reserved.</small>
      </div>
    </footer>
  );
};

export default Footer;